import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const GeneralInstructionsPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/instructions/general-instructions/"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>General Instructions Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="General Instructions Indianapolis IN"/>
        <meta name="description" content="endodontic treatment,root canal Endodontist, Endodontics, Adam B. Broady, DDS, MSD, Indianapolis IN."/>
        <meta name="og:description" content="endodontic treatment,root canal Endodontist, Endodontics, Adam B. Broady, DDS, MSD, Indianapolis IN."/>
        <meta property="og:url" content="https://meridianendoindy.com/instructions/general-instructions/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/instructions/general-instructions/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="General Instructions Indianapolis IN"/>
        <meta name="twitter:description" content="endodontic treatment,root canal Endodontist, Endodontics, Adam B. Broady, DDS, MSD, Indianapolis IN."/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="general-instructions-hero-section-bg hero-bg"
        fluid={hero_background}
        alt="Endodontics pc"
      >
      <div id="general-instructions-hero-section">
        <Container>
          <div className="col-lg-6 left-content">
            <h1 className="primary-text hero-heading">
              Home Care Instructions
            </h1>
            <p className="hero-text">
              Your tooth and surrounding gum tissue may be slightly tender for several days as a result of manipulation during treatment and previous condition of your tooth.
            </p>
            <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
          </div>
          <div className="col-lg-6">
            <Img fluid={hero_img} className="hero-img" alt="happy lady"/>
          </div>
        </Container>
      </div>
    </BackgroundImage>
    <section id="faded-bg"></section>
  <section id="general-instructions-section-1">
    <Container>
      <div className="col-lg-12">
        <h4> Home Care Instructions </h4>
        <p>
          We do a lot of work along your gum line and inside your tooth during root canal therapy. It's not uncommon for the tooth and gum tissue surrounding the area to be inflamed and sore for a few days following the procedure. To avoid further discomfort, don't chew on the side of your mouth where the root canal was performed. The area only has a temporary cover. You need to protect it until you see your regular dentist for a crown or similar cap. Don't skip regular hygiene during this time. Use simple over-the-counter NSAIDs, such as ibuprofen, naproxen sodium, aspirin, or acetaminophen as needed for pain.
        </p>
        <p>
          For safety reasons, avoid alcohol with these pain relievers. However, if the discomfort worsens and NSAIDs don't reduce pain, contact the office immediately.
        </p>
        <p>
          We have an after-hours answering service. Call day or night for severe pain or swelling.
        </p>
        <p><strong>
          For questions about your home care instructions, contact our office at <a href="tel:3178464980">317-846-4980.</a>
        </strong></p>
      </div>
    </Container>
  </section>
  <section id="general-instructions-section-2">
    <ContactForm/>
  </section>
    </Layout>
  )
}

export default GeneralInstructionsPage

export const query = graphql`
  query GeneralInstructionsPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "general-img" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
